
import React, {useEffect, useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box } from '@sqymagma/elements'
import Img from "gatsby-image"


export default({name, alt=""}) => {

  const graphImgs = useStaticQuery(graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "graphs"}}) {
      edges {
        node {
          base
          name
          childImageSharp {
            fluid(maxWidth: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
  
  `)

  const [graphImg, setGraphImg] = useState(null) 
  useEffect(() => {
      setGraphImg(graphImgs.allFile.edges.find(edge => edge.node.name === name))
  },[graphImgs,name])

  
  return (
    <Box width={1} textAlign="center" my="m" mh="s">
     {graphImg && 
      <Img fluid={graphImg.node.childImageSharp.fluid} alt={alt}/>
     } 
    </Box>
  )
}

